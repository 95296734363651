import "./App.css";
import Resort from "./components/OurResort/Resort";
import Specials from "./components/our Specials/Specials";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Rental from "./Pages/Rental";
import Contact from "./Pages/ContactUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Event } from "./Pages/Events/Event";
import Plan from "./Pages/Plans/Plan";
import { SuccessDisplay } from "./Pages/Success/Success";
import Cancel from "./Pages/Cancel/Cancel";
import Policy from "./Pages/PrivacyPolicy/Policy";
import Terms from "./Pages/TermAndCondition/Terms";
import OutdoorGetawaysPage from "./Pages/BlogDetails/outdoor-getaways-idaho";
import RomanticGetawaysPage from "./Pages/BlogDetails/romantic-getaway-packages-lewiston";
import Blog from "./Pages/Blog/Blog";
import Unforgettable from "./Pages/BlogDetails/unforgettable-fly-fishing-adventures-clearwater-river";
import SteelheadFishingBlog from "./Pages/BlogDetails/cosmic-waters-steelhead-fishing-spots";
import SalmonFishingMistakesBlog from "./Pages/BlogDetails/salmon-fishing-mistakes-clearwater-river";
import GrandviewGetawayBlog from "./Pages/BlogDetails/outdoor-adventures-grandview-getaway";
import BestBlogPage from "./Pages/BlogDetails/best-outdoor-getaways-romantic-retreats-idaho";
import GroupFishingTripsPage from "./Pages/BlogDetails/group-fishing-trips";
import RomanticGetaways from "./Pages/BlogDetails/romantic-getaways-on-a-budget";
import IdahoAdventures from "./Pages/BlogDetails/idaho-hunting-adventures";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Vacation" element={<Resort />} />
      <Route path="/events" element={<Event />} />
      <Route path="/specials" element={<Specials />} />
      <Route path="/Rental" element={<Rental />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/success" element={<SuccessDisplay />} />
      <Route path="/cancle" element={<Cancel />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/terms-condition" element={<Terms />} />
      {/* <Route path="/blog/:slug" element={<BlogDetails />} /> */}
      <Route path="/blogs" element={<Blog />} />
      <Route
        path="/blog/outdoor-getaways-idaho"
        element={<OutdoorGetawaysPage />}
      />
      <Route
        path="/blog/romantic-getaway-packages-lewiston"
        element={<RomanticGetawaysPage />}
      />
      <Route
        path="/blog/unforgettable-fly-fishing-adventures-clearwater-river"
        element={<Unforgettable />}
      />
      <Route
        path="/blog/cosmic-waters-steelhead-fishing-spots"
        element={<SteelheadFishingBlog />}
      />
      <Route
        path="/blog/salmon-fishing-mistakes-clearwater-river"
        element={<SalmonFishingMistakesBlog />}
      />
      <Route
        path="/blog/outdoor-adventures-grandview-getaway"
        element={<GrandviewGetawayBlog />}
      />
      <Route
        path="/blog/best-outdoor-getaways-romantic-retreats-idaho"
        element={<BestBlogPage />}
      />
      <Route
        path="/blog/group-fishing-trips"
        element={<GroupFishingTripsPage />}
      />
      <Route
        path="/blog/romantic-getaways-on-a-budget"
        element={<RomanticGetaways />}
      />
      <Route
        path="/blog/idaho-hunting-adventures"
        element={<IdahoAdventures />}
      />
    </Routes>
  );
}

export default App;
